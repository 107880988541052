import React, { useState, useEffect } from "react"
import * as styles from "./index.module.scss"
import AddToCart from "./AddToCart"
import currencyFormat from "../../../utils/currencyFormat"

const Product = ({ product: { id, name, selling_price } }) => {
  return (
    <div className={`col-sm-6 col-lg-4 col-xl-3 ${styles.product_wrapper}`}>
      <div>
        <div className={styles.product_img}>
          <img src={require(`../../../images/products/${id}.jpg`).default} alt={name} />
        </div>
      </div>
      <div>
        <h4>{name}</h4>
        <p>{currencyFormat(selling_price)}</p>
        <AddToCart id={id} name={name} />
      </div>
    </div>
  )
}

export default Product
