import React, { useContext, useState } from "react"
import { MdAddShoppingCart } from "react-icons/md"
import { toast } from "react-toastify"
import * as styles from "./AddToCart.module.scss"
import { CartContext } from "../../../contexts/Cart"

const min = 0
const max = 10

const AddToCart = ({ id = "", name = "" }) => {
  const [amount, setAmount] = useState(0)

  const {
    cart: { items },
    actions: { addToCart },
  } = useContext(CartContext)

  const add = () => {
    toast.success(`${name} wurde Ihrem Warenkorb hinzugefügt.`)
    if (items.find(item => item.id === id)) {
      // existing product, update the amount
      addToCart({ id, amount: amount || 1 })
      if (!amount) {
        setAmount(1)
      }
    } else {
      addToCart({ id, amount: amount || 1 })
      if (!amount) {
        setAmount(1)
      }
    }
  }

  return (
    <div className={styles.add_to_cart}>
      <button
        className={`btn ${styles.minus}`}
        onClick={() => setAmount(amount > min ? amount - 1 : 0)}
        disabled={amount <= min}
      />
      <span>{amount}</span>
      <button
        className={`btn ${styles.plus}`}
        onClick={() => setAmount(amount < max ? amount + 1 : max)}
        disabled={amount >= max}
      />
      <button className={`btn ${styles.cart}`} onClick={add}>
        <MdAddShoppingCart />
      </button>
    </div>
  )
}

export default AddToCart
